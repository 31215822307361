export const isDisabled = (currentStepIndex, watchFn, errors) => {
  const validationLogic = (watchFn, errors) => {
    if (!watchFn || !errors) {
      return true;
    }

    return (
      Object.keys(errors).length > 0 ||
      !Object.values(watchFn()).every(
        (value) => value !== null && value !== undefined && value !== ""
      )
    );
  };

  return validationLogic(watchFn, errors);
};
