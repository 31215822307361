import React, { useState } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
  useNavigate,
} from "react-router-dom";
import OnboardingPage from "./pages/Onboarding/OnboardingPage";
import HomePage from "./pages/Home/HomePage";
import OTPPage from "./pages/Onboarding/OTP";
import Login from "./pages/Onboarding/Login";
import MusicianProfile from "./pages/MusicianProfile/MusicianProfile";
import { ClientOnBoardingDialog } from "./components/ClientOnboarding/ClientOnBoardingDialog";
import { MusicianOnBoardingDialog } from "./components/MusicianOnboarding/MusicianOnboardingDialog";
import { ProfileOnBoardingDialog } from "./components/Onboarding/profileOnboardingDialog";
import { AdapterLuxon } from "@mui/x-date-pickers-pro/AdapterLuxon";
import { LocalizationProvider } from "@mui/x-date-pickers-pro/LocalizationProvider";
import {
  createTheme,
  StyledEngineProvider,
  ThemeProvider,
  Stack,
  Box,
} from "@mui/material";
import { Language, DollarCircle, LogOut } from "iconoir-react";

import "./App.css";
import themeObj from "./styles/theme";

import { AppBarToolbar } from "./components/AppBar";
import { AppBarLogo } from "./components/AppBar";
import { UserProfileMenuV2 } from "./components/UserProfileMenu/UserProfileMenuV2";

function App() {
  const AppContent = () => {
    const josysUiTheme = createTheme(themeObj);
    const navigate = useNavigate();

    const [user, setUser] = useState({
      fullName: "John Doe",
      firstName: "John",
      lastName: "Doe",
      email: "john.doe@example.com",
      avatarUrl: "https://example.com/avatar.jpg",
    });

    const userDetails = user;

    const languages = {
      english: {
        label: "(English) English [American]",
        selectedLabel: "English",
      },
      japanese: {
        label: "(日本語) Japanese",
        selectedLabel: "Japanese",
      },
    };

    const currencies = {
      USD: {
        code: "USD",
        name: "Dollar",
        symbol: "$",
      },
      JPY: {
        code: "JPY",
        name: "Yen",
        symbol: "¥",
      },
    };

    const menulist = [
      {
        key: "language",
        label: "Language",
        selectedValueLabel: languages["english"].selectedLabel,
        icon: <Language />,
        subMenuItems: Object.entries(languages).map(([key, value]) => ({
          key: key,
          label: value.label,
          onClick: () => {},
          selected: "english" === key,
        })),
      },
      {
        key: "currency",
        label: "Currency",
        searchInputPlaceholder: "Search Currencies",
        selectedValueLabel: `${currencies["JPY"].symbol} (${currencies["JPY"].code})`,
        icon: <DollarCircle />,
        subMenuItems: Object.entries(currencies).map(([key, value]) => ({
          key: key,
          label: `${value.symbol} (${value.code}) ${value.name}`,
          onClick: () => {},
          selected: "JPY" === key,
        })),
      },
      {
        key: "signOut",
        label: "Sign Out",
        icon: <LogOut />,
        divider: true,
        sx: {
          color: "red",
        },
        onClick: () => {
          setUser(null);
          navigate("/onboarding/login");
        },
      },
    ];

    // Add this new component for protected routes
    const ProtectedRoute = ({ children }) => {
      console.log(user, "user");
      return user ? children : <Navigate to="/onboarding/login" replace />;
    };

    return (
      <LocalizationProvider dateAdapter={AdapterLuxon}>
        <AppBarToolbar>
          <Stack
            flexDirection="row"
            justifyContent="space-between"
            alignItems="center"
            width="100%"
            gap="2rem"
          >
            <AppBarLogo onLogoClick={() => {}} />

            {
              <Box sx={{ marginInlineStart: "auto" }}>
                {user && (
                  <UserProfileMenuV2
                    menuItems={menulist}
                    userDetails={userDetails}
                  />
                )}
              </Box>
            }
          </Stack>
        </AppBarToolbar>

        <ThemeProvider theme={josysUiTheme}>
          <StyledEngineProvider injectFirst>
            <Routes>
              <Route path="/onboarding" element={<OnboardingPage />} />
              <Route path="/onboarding/otp" element={<OTPPage />} />
              <Route path="/onboarding/login" element={<Login />} />

              {/* Protected routes */}
              <Route
                path="/"
                element={
                  <ProtectedRoute>
                    <HomePage />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/jobposting"
                element={
                  <ProtectedRoute>
                    <ClientOnBoardingDialog />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/bidding"
                element={
                  <ProtectedRoute>{/* Your component */}</ProtectedRoute>
                }
              />
              <Route
                path="/musicianonboarding"
                element={
                  <ProtectedRoute>
                    <MusicianOnBoardingDialog />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/profileonboarding"
                element={
                  <ProtectedRoute>
                    <ProfileOnBoardingDialog />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/musicianprofile"
                element={
                  <ProtectedRoute>
                    <MusicianProfile />
                  </ProtectedRoute>
                }
              />
            </Routes>
          </StyledEngineProvider>
        </ThemeProvider>
      </LocalizationProvider>
    );
  };

  return (
    <Router>
      <AppContent />
    </Router>
  );
}

export default App;
