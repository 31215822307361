import React from "react";

import { BottomSheetDialogStyled } from "./BottomSheetDialog.styled";

import {
  DialogTitle,
  IconButton,
  DialogActions,
  DialogContent,
} from "@mui/material";

import CloseIcon from "@mui/icons-material/Close";
export function BottomSheetDialog({
  children,
  title,
  open,
  actions,
  onClose,
  ...rest
}) {
  return (
    <BottomSheetDialogStyled
      fullScreen
      title
      actions
      open={open}
      onClose={onClose}
      {...rest}
    >
      <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
        {title}
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={onClose}
        sx={(theme) => ({
          position: "absolute",
          right: 8,
          top: 8,
          color: theme.palette.grey[500],
        })}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent sx={{ paddingX: "4rem", paddingY: "2rem" }}>
        {children}
      </DialogContent>

      {actions && (
        <DialogActions sx={{ py: "0.8rem", px: "2.4rem" }}>
          {actions}
        </DialogActions>
      )}
    </BottomSheetDialogStyled>
  );
}
