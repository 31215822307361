import React, { useRef } from "react";
import "./MusicianProfile.css";
import Card from "../card/card";
import Profile from "./Profile.js";
import Dashboard from "./Dashboard.js";
import Reviews from "./Reviews.js";

const MusicianProfile = () => {
  const scrollContainerRef = useRef(null);

  const scrollLeft = () => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollBy({
        left: -660, // Adjust this value to control the scroll amount
        behavior: "smooth",
      });
    }
  };

  const scrollRight = () => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollBy({
        left: 660, // Adjust this value to control the scroll amount
        behavior: "smooth",
      });
    }
  };

  return (
    <div className="musician-profile">
      <h2>Your Profile</h2>
      <div className="musician-profile-content">
        <div className="musician-profile-artist-info">
          <div className="musician-profile-details">
            <Profile />
          </div>
          <div className="musician-profile-bio">
            <Dashboard />
          </div>
        </div>
        <div className="musician-profile-chat-section">
        </div>
      </div>
      <div className="musician-profile-for-you">
        <h2>For You</h2>
        <div className="card-scroll-wrapper">
          <button className="scroll-button left" onClick={scrollLeft}>
            &lt;
          </button>
          <div className="card-scroll-container" ref={scrollContainerRef}>
            {[...Array(10)].map((_, index) => (
              <Card key={index} />
            ))}
          </div>
          <button className="scroll-button right" onClick={scrollRight}>
            &gt;
          </button>
        </div>
      </div>
      <div className="musician-profile-reviews">
        <h2>Reviews</h2>
        <Reviews />
      </div>
    </div>
  );
};

export default MusicianProfile;
