import React from "react";
import "./NextButton.css";

function NextButton({ onClick, text }) {
  return (
    <div className="button-container">
      <button className="next-button" onClick={onClick}>
        {text}
      </button>
    </div>
  );
}

export default NextButton;
