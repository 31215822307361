import React from "react";
import OTPPage from "../../components/Onboarding/OTPPage";

const OnboardingPage = () => {
  return (
    <div className="otp-page">
      <OTPPage />
    </div>
  );
};

export default OnboardingPage;
