import {
  Menu,
  menuClasses,
  MenuItem,
  menuItemClasses,
  Paper,
} from "@mui/material";
import { styled } from "@mui/material/styles";

export const MenuStyled = styled(Menu)(({ theme }) => {
  const style = {
    [`& .${menuClasses.paper}`]: {
      boxShadow:
        "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
      minWidth: "20rem",
      margin: "0 0.5rem",
      borderRadius: "8px",
      overflow: "visible",

      "& .MuiList-root": {
        padding: "0",
        overflow: "visible",
      },
    },
  };

  return style;
});

export const PaperStyled = styled(Paper)(({ theme }) => {
  const style = {
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    minWidth: "20rem",
    margin: "0 0.5rem",
    borderRadius: "8px",
  };

  return style;
});

export const MenuItemStyled = styled(MenuItem, {
  shouldForwardProp: (prop) => prop !== "iconPosition",
})(({ theme }) => ({
  padding: "1.2rem 1.6rem 1.2rem 1.2rem",
  overflow: "visible",
  borderRadius: "8px",

  [`&.${menuItemClasses.root}`]: {
    "&:hover": {
      backgroundColor: "pink",
      ".MuiTypography-root": {
        fontWeight: 500,
      },
    },
  },
  [`&.${menuItemClasses.selected}`]: {
    backgroundColor: "red",
    color: "yellow",
  },
}));
