import React from "react";
import "./card.css";
import sampleImage from "../../assets/samples/card.jpg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBookmark } from "@fortawesome/free-solid-svg-icons";

const Card = () => {
  return (
    <div className="card">
      <div className="card-image-container">
        <img src={sampleImage} alt="Card" className="card-image" />
        <div className="card-date">Jun 12</div>
      </div>
      <div className="card-content">
        <div className="card-title">
          <div className="card-bid-amount">
            <h4>Starting at</h4>
            <p>₹ 4500</p>
          </div>
          <div className="card-buttons">
            <button>Bid Now</button>
            <FontAwesomeIcon icon={faBookmark} className="bookmark-icon" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Card;
