import { Stack } from '@mui/material';
import _isFunction from 'lodash/isFunction';
import { MenuItemStyled } from '../Menu/Menu.styled';
import { Typography } from '../Typography';

export function MenuItem({
  icon,
  label,
  disabled,
  onClick,
  closeMenu,
  children,
  ...rest
}) {
  return (
    <MenuItemStyled
      disabled={disabled}
      onClick={(event) => {
        if (_isFunction(closeMenu)) {
          closeMenu();
        }

        if (_isFunction(onClick)) {
          onClick(event);
        }
      }}
      {...rest}
    >
      {(icon || label) && (
        <Stack
          gap="1.2rem"
          alignItems="center"
          width="100%"
          flexDirection="row"
        >
          {icon}
          {label && <Typography variant="body">{label}</Typography>}
        </Stack>
      )}

      {children}
    </MenuItemStyled>
  );
}
