import React, { useState, useEffect } from "react";
import { Button } from "../Button";

import { Stepper, Step, StepLabel, Stack } from "@mui/material";

import { Typography } from "../Typography";

import { BottomSheetDialog } from "../BottomSheetDialog";
import { useStepper } from "../../hooks/useStepper";

import { EventDetails } from "./EventDetails";
import { Budget } from "./Budget";
import { Review } from "./Review";
import { About } from "./About";

import { isDisabled } from "./utils";

import { useForm } from "react-hook-form";

export const ClientOnBoardingDialog = () => {
  const [isDialogOpen, setIsDialogOpen] = useState(true);

  const [formValues, setFormValues] = useState({});

  const aboutResolver = async (values) => {
    const errors = {};
    const requiredFields = [
      "subCategory",
      "category",
      "description",
      "jobTitle",
      "language",
      "genre",
    ];

    requiredFields.forEach((field) => {
      if (!values[field]) {
        errors[field] = {
          type: "required",
          message: "This field is required",
        };
      }
    });

    return {
      values: Object.keys(errors).length === 0 ? values : {},
      errors: Object.keys(errors).length > 0 ? errors : {},
    };
  };

  const {
    control: aboutControl,
    watch: aboutWatch,
    setValue: aboutSetValue,
    handleSubmit: aboutHandleSubmit,
    formState: {
      errors: aboutErrors,
      isDirty: aboutIsDirty,
      touchedFields: aboutTouchedFields,
    },
  } = useForm({
    mode: "onBlur",
    resolver: aboutResolver,
  });

  const eventDetailsResolver = async (values) => {
    const errors = {};
    const requiredFields = [
      "event_location",
      // "event_date", "event_time"
    ];

    requiredFields.forEach((field) => {
      if (!values[field]) {
        errors[field] = {
          type: "required",
          message: "This field is required",
        };
      }
    });

    return {
      values: Object.keys(errors).length === 0 ? values : {},
      errors: errors,
    };
  };

  const {
    control: eventDetailsControl,
    watch: eventDetailsWatch,
    setValue: eventDetailsSetValue,
    formState: { errors: eventDetailsErrors },
    handleSubmit: eventDetailsHandleSubmit,
  } = useForm({
    mode: "onBlur",
    resolver: eventDetailsResolver,
  });

  const budgetResolver = async (values) => {
    const errors = {};
    const requiredFields = ["from", "to"];

    requiredFields.forEach((field) => {
      if (!values[field]) {
        errors[field] = {
          type: "required",
          message: "This field is required",
        };
      }
    });

    return {
      values: Object.keys(errors).length === 0 ? values : {},
      errors: errors,
    };
  };

  const {
    control: budgetControl,
    watch: budgetWatch,
    setValue: budgetSetValue,
    formState: { errors: budgetErrors },
    handleSubmit: budgetHandleSubmit,
  } = useForm({
    mode: "onBlur",
    resolver: budgetResolver,
  });

  const step1 = (
    <About
      control={aboutControl}
      errors={aboutErrors}
      watch={aboutWatch}
      setValue={aboutSetValue}
    />
  );
  const step2 = (
    <>
      <EventDetails
        control={eventDetailsControl}
        errors={eventDetailsErrors}
        watch={eventDetailsWatch}
        setValue={eventDetailsSetValue}
      />
    </>
  );
  const step3 = (
    <Budget
      control={budgetControl}
      errors={budgetErrors}
      watch={budgetWatch}
      setValue={budgetSetValue}
    />
  );

  const step4 = <Review formValues={formValues} />;

  const stepperState = {
    stepArray: [step1, step2, step3, step4],
    horizontalStepperList: ["About", "Event Details", "Budget", "Review"],
  };

  const { currentStepIndex, step, isFirstStep, isLastStep, back, next, goTo } =
    useStepper(stepperState.stepArray);

  const handleStepSubmit = (stepIndex) => {
    const submitHandlers = {
      0: aboutHandleSubmit,
      1: eventDetailsHandleSubmit,
      2: budgetHandleSubmit,
    };

    const submitHandler = submitHandlers[stepIndex];
    if (submitHandler) {
      submitHandler((formValues) => {
        setFormValues((prev) => ({ ...prev, ...formValues }));
      })();
    }
  };


  return (
    <Stack>
      <BottomSheetDialog
        title={
          <Typography variant="heading" fontWeight="bold">
            Event Job Posting
          </Typography>
        }
        open={isDialogOpen}
        onClose={() => {
          goTo(0);
          setIsDialogOpen(false);
        }}
        actions={
          <Stack
            direction="row"
            justifyContent={isFirstStep ? "flex-end" : "space-between"}
            width="100%"
          >
            {!isFirstStep && (
              <Button variant="secondary" autoFocus onClick={back}>
                Back
              </Button>
            )}
            <Button
              autoFocus
              disabled={isDisabled(
                currentStepIndex,
                aboutWatch,
                aboutErrors,
                eventDetailsWatch,
                eventDetailsErrors,
                budgetWatch,
                budgetErrors
              )}
              onClick={() => {
                handleStepSubmit(currentStepIndex);

                next();

                if (isLastStep) {
                  setIsDialogOpen(false);
                  goTo(0);
                }
              }}
            >
              {isLastStep ? "Submit" : "Next"}
            </Button>
          </Stack>
        }
      >
        <Stack spacing="3rem">
          <Stepper activeStep={currentStepIndex}>
            {stepperState.horizontalStepperList.map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
          {step}
        </Stack>
      </BottomSheetDialog>
      <Button onClick={() => setIsDialogOpen(true)}>Open Dialog</Button>
    </Stack>
  );
};
