export const updateMusician = (name, value) => ({
  type: "UPDATE_MUSICIAN",
  payload: { name, value },
});

export const updateSkills = (skills) => ({
  type: "UPDATE_SKILLS",
  payload: { skills },
});

export const setProfilePicture = (picture) => ({
  type: "SET_PROFILE_PICTURE",
  payload: picture,
});
