export const typographyOptions = {
  titleHero: { fontSize: "7.2rem", lineHeight: "8.64rem" },
  titlePage: { fontSize: "4.8rem", lineHeight: "5.76rem" },
  subtitle: { fontSize: "3.2rem", lineHeight: "3.84rem" },
  heading: { fontSize: "2.4rem", lineHeight: "2.88rem" },
  subHeading: { fontSize: "2rem", lineHeight: "2.4rem" },
  bodyBase: { fontSize: "1.6rem", lineHeight: "2.24rem" },
  bodyStrong: { fontSize: "1.6rem", lineHeight: "2.24rem" },
  bodyEmphasis: { fontSize: "1.6rem", lineHeight: "2.24rem" },
  bodyLink: { fontSize: "1.6rem", lineHeight: "2.24rem" },
  bodySmall: { fontSize: "1.4rem", lineHeight: "1.96rem" },
  bodySmallStrong: { fontSize: "1.4rem", lineHeight: "1.96rem" },
  bodyCode: { fontSize: "1.6rem", lineHeight: "1.6rem" },
};

export const fontWeightStyles = {
  regular: { fontWeight: 400 },
  medium: { fontWeight: 500 },
  semiBold: { fontWeight: 600 },
  bold: { fontWeight: 700 },
};

export const typographyFontStyleOptions = {
  fontSize: 14,
  htmlFontSize: 10,
  fontFamily: "inherit",
};

export const typographyThemeOptions = {
  ...typographyFontStyleOptions,
  ...typographyOptions,
};
