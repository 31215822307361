import { styled, Dialog } from "@mui/material";

export const BottomSheetDialogStyled = styled(Dialog)({
  height: "90%",
  top: "auto",
  "& .MuiDialog-paper": {
    borderTopLeftRadius: "12px",
    borderTopRightRadius: "12px",
  },
});
