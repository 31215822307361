import React, { useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import StepIndicator from "../StepIndicator/stepIndicator";
import NextButton from "../Buttons/NextButton";
import { useForm } from "react-hook-form";
import { updateJobPost } from "../../store/jobPostActions";
import { Typography } from "../Typography";
import { Stack, Box } from "@mui/material";
import { TextFieldV2 } from "../Forms/Fields/TextFieldV2";
import axios from "axios";

export const Budget = ({ control, errors, watch, setValue }) => {
  return (
    <Stack direction="row" justifyContent="space-between">
      <Stack gap="2rem" width="30%">
        <Typography variant="titlePage" fontWeight="bold">
          Outline Your Budget!
        </Typography>
        <Typography variant="subHeading">
          Set a budget to find the best talent within your range.
        </Typography>
      </Stack>

      <Box width="50%">
        <Stack spacing="4rem" direction="row" alignItems="center">
          <TextFieldV2
            control={control}
            name="from"
            label="From"
            error={!!errors.from}
            helperText={errors.from?.message}
            placeholder="Eg. 1000"
            required
          />

          <TextFieldV2
            control={control}
            name="to"
            label="To"
            error={!!errors.to}
            helperText={errors.to?.message}
            placeholder="Eg. 1000"
            required
          />
        </Stack>
      </Box>
    </Stack>
  );
};
