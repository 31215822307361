import React from "react";
import Onboarding from "../../components/Onboarding/Onboarding";

const OnboardingPage = () => {
  return (
    <div className="onboarding-page">
      <Onboarding />
    </div>
  );
};

export default OnboardingPage;
