// frontend/src/components/Onboarding/OTPPage.js
import React, { useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import otpIcon from "../../assets/icons/tick-inside-circle.png";
import axios from "axios";
// import "./OTPPage.css";
import { Stack, Typography, TextField } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { Button } from "../Button";

const OTPPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const email = location.state?.email || "example@gmail.com";

  const [otp, setOtp] = useState(["", "", "", "", ""]);
  const inputRefs = useRef([]);
  const [resendMessage, setResendMessage] = useState("");
  const [error, setError] = useState("");

  const handleChange = (e, index) => {
    const value = e.target.value;
    if (/^[0-9]$/.test(value) || value === "") {
      const newOtp = [...otp];
      newOtp[index] = value;
      setOtp(newOtp);

      // Move to the next input field if it exists
      if (value !== "" && index < otp.length - 1) {
        inputRefs.current[index + 1].focus();
      }
    }
  };

  const handleKeyDown = (e, index) => {
    if (e.key === "ArrowRight" && index < otp.length - 1) {
      const currentInput = inputRefs.current[index];
      if (currentInput.value !== "") {
        currentInput.setSelectionRange(0, currentInput.value.length);
        return;
      }
      const nextInput = inputRefs.current[index + 1];
      nextInput.focus();
      if (nextInput.value) {
        nextInput.setSelectionRange(
          nextInput.value.length,
          nextInput.value.length
        ); // Move cursor to the right of the value
      }
    } else if (e.key === "ArrowLeft" && index > 0) {
      const prevInput = inputRefs.current[index - 1];
      prevInput.focus();
      if (prevInput.value) {
        prevInput.setSelectionRange(
          prevInput.value.length,
          prevInput.value.length
        ); // Move cursor to the right of the value
      }
    }
  };

  const maskEmail = (email) => {
    const [localPart, domain] = email.split("@");
    const maskedLocalPart =
      localPart.slice(0, 3) + "*".repeat(localPart.length - 3);
    return `${maskedLocalPart}@${domain}`;
  };

  const handleOTP = async () => {
    try {
      const response = await axios.post(
        "http://localhost:5000/api/v1/users/verify-otp",
        {
          email: email,
          otp: otp.join(""),
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
          withCredentials: true,
        }
      );

      if (response && response.data) {
        console.log("Cookies received:", document.cookie);

        const data = response.data;
        if (response.status === 200) {
          navigate("/");
        } else {
          setError(data.message);
        }
      } else {
        setError("Unexpected response format");
      }
    } catch (error) {
      if (error.response) {
        // The server responded with a status code other than 2xx
        setError(error.response.data.message || "An error occurred");
      } else if (error.request) {
        // The request was made but no response was received
        setError(
          "No response from server. Please check your network connection."
        );
      } else {
        // Something else happened in setting up the request
        setError("An unexpected error occurred: " + error.message);
      }
    }
  };

  const handleResend = async () => {
    try {
      const response = await fetch(
        "http://localhost:5000/api/v1/users/resend-otp",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ email }),
        }
      );

      const data = await response.json();
      if (response.ok) {
        setResendMessage("OTP has been resent to your email.");
      } else {
        console.log(data.message);
        setError(data.message);
      }
    } catch (error) {
      setError("An error occurred while resending OTP");
    }
  };

  const theme = useTheme();

  return (
    <Stack
      sx={{
        height: "90vh",
        backgroundColor: theme.mochshaColorPalette.mistyMint[900],
      }}
    >
      <Stack
        spacing={4}
        alignItems="center"
        justifyContent="center"
        sx={{
          backgroundColor: "white",
          paddingY: "5rem",
          paddingX: "30rem",
          borderRadius: theme.shape.borderRadius,
          boxShadow: theme.shadows[2],
          margin: "auto",
          textAlign: "center",
        }}
      >
        <img
          src={otpIcon}
          alt="OTP Icon"
          style={{ width: "4rem", height: "4rem" }}
        />
        <Typography variant="titlePage" fontWeight="bold">
          Verify OTP
        </Typography>
        <Typography variant="bodyBase">
          An OTP has been sent to {maskEmail(email)}
        </Typography>
        <Stack direction="row" spacing={1} justifyContent="center">
          {otp.map((digit, index) => (
            <TextField
              key={index}
              type="text"
              inputProps={{ maxLength: 1, style: { textAlign: "center" } }}
              value={digit}
              onChange={(e) => handleChange(e, index)}
              onKeyDown={(e) => handleKeyDown(e, index)}
              inputRef={(el) => (inputRefs.current[index] = el)}
              variant="outlined"
              sx={{ width: "6rem" }}
            />
          ))}
        </Stack>
        <Button onClick={handleOTP}>Verify</Button>
        {error && <Typography color="error">{error}</Typography>}
        <Typography variant="bodyBase">
          Didn't receive the OTP?{" "}
          <Typography
            component="span"
            variant="bodyBase"
            sx={{
              cursor: "pointer",
              color: theme.mochshaColorPalette.vibrantViolet[900],
            }}
            onClick={handleResend}
          >
            Resend OTP
          </Typography>
        </Typography>
        {resendMessage && (
          <Typography variant="bodyBase">{resendMessage}</Typography>
        )}
      </Stack>
    </Stack>
  );
};

export default OTPPage;
