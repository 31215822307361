import React, { useState, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { updateJobPost } from "../../store/jobPostActions";

import { Typography } from "../Typography";

import { TextFieldV2 } from "../Forms/Fields/TextFieldV2";

import { Stack, Box } from "@mui/material";

import { DatePickerFieldV2 } from "../Forms/Fields/DatePickerFieldV2";

import TimePicker from "./TimePicker";

export const EventDetails = ({ control, errors, watch, setValue }) => {
  const dispatch = useDispatch();
  const jobPostData = useSelector((state) => state.jobPost.jobPostData);

  const [eventDates, setEventDates] = useState([""] || jobPostData.event_dates);

  const addEventDate = () => {
    setEventDates([...eventDates, ""]);
  };

  const removeLastEventDate = () => {
    setEventDates((prevDates) => prevDates.slice(0, -1));
  };

  const handleDateChange = (index, value) => {
    const newEventDates = [...eventDates];
    newEventDates[index] = value;
    setEventDates(newEventDates);
    dispatch(updateJobPost("event_dates", newEventDates));
  };

  const handleInputChange = (e) => {
    const { id, value } = e.target;
    dispatch(updateJobPost(id, value));
  };

  const handleTimeChange = useCallback(
    (field, value) => {
      if (jobPostData[field] !== value) {
        dispatch(updateJobPost(field, value));
      }
    },
    [dispatch, jobPostData]
  );

  const navigate = useNavigate();

  const handleNext = () => {
    console.log(jobPostData);
    navigate("/jobposting/budget");
  };

  return (
    <Stack direction="row" justifyContent="space-between">
      <Stack gap="2rem" width="30%">
        <Typography variant="titlePage" fontWeight="bold">
          Set the Scene!
        </Typography>
        <Typography variant="subHeading">
          Share the specifics to ensure the perfect event.
        </Typography>
      </Stack>

      <Box width="50%">
        <Stack spacing="4rem">
          <TextFieldV2
            control={control}
            name="event_location"
            label="Where is your event?"
            error={!!errors.event_location}
            helperText={errors.event_location?.message}
            placeholder="Eg. 123 Main St, New York, NY 10001"
            required
          />

          <DatePickerFieldV2
            control={control}
            label="Select your event date(s)"
            name="datepicker"
            labelContext="Choose the main date and add additional dates if needed."
            required
          />

          <Stack spacing="2rem">
            <label>Set Your Event Time & Duration</label>
            <Stack
              className="event-details-time-input"
              direction="row"
              spacing="2rem"
            >
              <TimePicker control={control} name="from_time" />
              <Typography>To</Typography>
              <TimePicker control={control} name="to_time" />
            </Stack>
          </Stack>
        </Stack>
      </Box>
    </Stack>
  );
};
