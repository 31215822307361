import { UserCircle } from "iconoir-react";
import {
  HelperTypographyStyled,
  ImageContainer,
  ImageUploadContainer,
} from "./ImageUploadInput.styled";
import { Box } from "@mui/material";
import { Typography } from "../../../Typography";
import { IconoirIcon } from "../../../IconoirIcon";

export function ImagePlaceholder({ error, onImageUploadHandler }) {

  return (
    <ImageContainer>
      <ImageUploadContainer error={error} onClick={onImageUploadHandler}>
        <IconoirIcon icon={UserCircle} />

        <Box padding={{ p: "0.8rem" }}>
          <Typography variant="body" fontWeight="medium">
            {error ? "Reupload" : "Upload File"}
          </Typography>
        </Box>
      </ImageUploadContainer>

      <HelperTypographyStyled
        variant="smallText"
        error={error}
      >
        {error
          ? "Incorrect Format. Supported formats: png, jpg, jpeg, pdf, docx, doc"
          : "Supported formats: png, jpg, jpeg, pdf, docx, doc"}
      </HelperTypographyStyled>
    </ImageContainer>
  );
}
