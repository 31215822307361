import _isFunction from 'lodash/isFunction';
import _truncate from 'lodash/truncate';
import { Box, Stack } from '@mui/material';
import { NavArrowDown, NavArrowUp } from 'iconoir-react';
import { useState } from 'react';

import { IconButtonStyled } from './UserProfileMenu.styled';
import { IconoirIcon } from '../IconoirIcon';
import { Menu } from './Menu';
import { MenuItem } from './MenuItem';
import { Typography } from '../Typography';
import { UserAvatar } from '../Avatars';
import { UserDetails } from './UserDetails';

export function UserProfileMenuV2({
  userDetails,
  onUserDetailsClick,
  fullNameMaxLength = 25,
  ...props
}) {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const truncatedFullName = userDetails.fullName
    ? _truncate(userDetails.fullName, {
        length: fullNameMaxLength,
        omission: '...',
      })
    : '';

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
      <IconButtonStyled
        size="small"
        onClick={handleClick}
        isOpened={open}
      >
        <Stack direction="row" alignItems="center" gap={1}>
          <UserAvatar
            firstName={userDetails.firstName}
            lastName={userDetails.lastName}
            src={userDetails.avatarUrl}
          />
          <Box>
            <Typography variant="body" fontWeight="medium">
              {truncatedFullName}
            </Typography>
          </Box>

          {open ? (
            <IconoirIcon width="2.5rem" height="2.5rem" icon={NavArrowUp} />
          ) : (
            <IconoirIcon width="2.5rem" height="2.5rem" icon={NavArrowDown} />
          )}
        </Stack>
      </IconButtonStyled>
      <Menu
        anchorEl={anchorEl}
        open={open}
        closeMenu={handleClose}
        sx={{
          '& .MuiPaper-root': {
            marginTop: '0.5rem',
            width: '29rem',
          },
        }}
        {...props}
      >
        <MenuItem
          key="userDetails"
          onClick={() => {
            if (_isFunction(onUserDetailsClick)) {
              onUserDetailsClick();
            }
          }}
          sx={{
            pointerEvents: _isFunction(onUserDetailsClick) ? 'auto' : 'none',
            '&:hover': {
              backgroundColor: 'unset !important',
              '.MuiTypography-subText': {
                fontWeight: `400 !important`,
              },
            },
          }}
          disableRipple
          disableTouchRipple
        >
          <UserDetails {...userDetails} />
        </MenuItem>
      </Menu>
    </Box>
  );
}
