import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useStepper } from "../../hooks/useStepper";
import PInfo from "../MusicianOnboarding/PInfo.js";
import Onboarding from "./Onboarding.js";
import { StepperPage } from "../StepperPage";

export const ProfileOnBoardingDialog = () => {
  const [formValues, setFormValues] = useState({});

  // Define the steps
  const steps = [
    {
      label: "About you",
      component: PInfo,
      watchName: "pInfo",
    },
    {
      label: "Persona",
      component: Onboarding,
      watchName: "persona",
    },
  ];

  // Define resolver functions for each step
  const pInfoResolver = async (values) => {
    const errors = {};
    const requiredFields = [
      "firstName",
      "lastName",
      "state",
      "city",
      "displayName",
      "profilePicture",
      "socials",
    ]; // Add required fields for PInfo

    requiredFields.forEach((field) => {
      if (!values[field]) {
        errors[field] = {
          type: "required",
          message: "This field is required",
        };
      }
    });

    return {
      values: Object.keys(errors).length === 0 ? values : {},
      errors: Object.keys(errors).length > 0 ? errors : {},
    };
  };

  const {
    control: pInfoControl,
    watch: pInfoWatch,
    setValue: pInfoSetValue,
    handleSubmit: pInfoHandleSubmit,
    formState: { errors: pInfoErrors },
  } = useForm({
    mode: "onBlur",
    resolver: pInfoResolver,
  });

  const personaResolver = async (values) => {
    const errors = {};
    const requiredFields = ["personaType"]; // Add required fields for Persona

    requiredFields.forEach((field) => {
      if (!values[field]) {
        errors[field] = {
          type: "required",
          message: "This field is required",
        };
      }
    });

    return {
      values: Object.keys(errors).length === 0 ? values : {},
      errors: errors,
    };
  };

  // Initialize form controls for each step

  const {
    control: personaControl,
    watch: personaWatch,
    setValue: personaSetValue,
    handleSubmit: personaHandleSubmit,
    formState: { errors: personaErrors },
  } = useForm({
    mode: "onBlur",
    resolver: personaResolver,
  });

  // Create step components
  const step1 = (
    <PInfo
      control={pInfoControl}
      errors={pInfoErrors}
      watch={pInfoWatch}
      setValue={pInfoSetValue}
    />
  );
  const step2 = (
    <Onboarding
      control={personaControl}
      errors={personaErrors}
      watch={personaWatch}
      setValue={personaSetValue}
    />
  );

  // Set up stepper state and navigation
  const stepperState = {
    stepArray: [step1, step2],
    horizontalStepperList: ["About you", "Persona"],
  };

  const { currentStepIndex, step, isFirstStep, isLastStep, back, next, goTo } =
    useStepper(stepperState.stepArray);

  // Handle step submission
  const handleStepSubmit = (stepIndex) => {
    const submitHandlers = {
      0: pInfoHandleSubmit,
      1: personaHandleSubmit,
    };

    const submitHandler = submitHandlers[stepIndex];
    if (submitHandler) {
      submitHandler((formValues) => {
        setFormValues((prev) => ({ ...prev, ...formValues }));
      })();
    }
  };

  return (
    <StepperPage
      steps={steps}
      currentStepIndex={currentStepIndex}
      step={step}
      isFirstStep={isFirstStep}
      isLastStep={isLastStep}
      back={back}
      next={next}
      goTo={goTo}
      handleStepSubmit={handleStepSubmit}
    />
  );
};
