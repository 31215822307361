import React from "react";
import { Stack, useTheme } from "@mui/material";
import { Controller } from "react-hook-form";
import "./TimePicker.css";

const TimePicker = ({ label, control, name }) => {
  const defaultTime = "12:00 AM";

  const theme = useTheme();

  return (
    <div className="time-picker-container">
      <Controller
        name={name}
        control={control}
        defaultValue={defaultTime} // You can customize this to accept a specific default time
        render={({ field: { onChange, value } }) => {
          const [hour, minute, period] = value
            ? value.split(/[: ]/)
            : ["12", "00", "AM"];

          const handleHourChange = (increment) => {
            const newHour = (Number(hour) + increment) % 12 || 12; // Ensure hour is 1-12
            onChange(
              `${newHour.toString().padStart(2, "0")}:${minute} ${period}`
            );
          };

          const handleMinuteChange = (increment) => {
            const newMinute = (Number(minute) + increment) % 60; // Ensure minute is 0-59
            onChange(
              `${hour}:${newMinute.toString().padStart(2, "0")} ${period}`
            );
          };

          const handlePeriodChange = () => {
            const newPeriod = period === "AM" ? "PM" : "AM";
            onChange(`${hour}:${minute} ${newPeriod}`);
          };

          return (
            <Stack className="time-picker" direction="row">
              <Stack className="time-selector">
                <button type="button" onClick={() => handleHourChange(1)}>
                  &#708;
                </button>
                <div className="time-display">{hour}</div>
                <button type="button" onClick={() => handleHourChange(-1)}>
                  &#709;
                </button>
              </Stack>
              <span>:</span>
              <Stack className="time-selector">
                <button type="button" onClick={() => handleMinuteChange(1)}>
                  &#708;
                </button>
                <div className="time-display">{minute}</div>
                <button type="button" onClick={() => handleMinuteChange(-1)}>
                  &#709;
                </button>
              </Stack>
              <Stack className="time-selector period-selector">
                <button type="button" onClick={handlePeriodChange}>
                  &#708;
                </button>
                <div className="time-display">{period}</div>
                <button type="button" onClick={handlePeriodChange}>
                  &#709;
                </button>
              </Stack>
            </Stack>
          );
        }}
      />
    </div>
  );
};

export default TimePicker;
