import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Stack,
  Box,
} from "@mui/material";
import { Typography } from "../Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

export const Review = ({ formValues }) => {
  return (
    <Stack direction="row" justifyContent="space-between">
      <Stack gap="2rem" width="30%">
        <Typography variant="titlePage" fontWeight="bold">
          Review Your Post
        </Typography>
        <Typography variant="subHeading">
          Ensure everything looks perfect publishing
        </Typography>
      </Stack>

      <Box width="50%">
        <Stack spacing="0rem">
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography fontWeight="bold" variant="heading">
                About
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Stack spacing="1rem">
                <Stack>
                  <Typography fontWeight="bold" variant="subHeading">
                    Title
                  </Typography>
                  <Typography fontWeight="regular" variant="bodyBase">
                    {formValues.jobTitle}
                  </Typography>
                </Stack>
                <Stack>
                  <Typography fontWeight="bold" variant="subHeading">
                    Description
                  </Typography>
                  <Typography fontWeight="regular" variant="bodyBase">
                    {formValues.description}
                  </Typography>
                </Stack>
                <Stack>
                  <Typography fontWeight="bold" variant="subHeading">
                    Category & Subcategory
                  </Typography>
                  <Typography fontWeight="regular" variant="bodyBase">
                    {formValues.category} / {formValues.subCategory}
                  </Typography>
                </Stack>
              </Stack>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2a-content"
              id="panel2a-header"
            >
              <Typography fontWeight="bold" variant="heading">
                Event Details
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Stack spacing="1rem">
                <Stack>
                  <Typography fontWeight="bold" variant="subHeading">
                    Event Venue
                  </Typography>
                  <Typography fontWeight="regular" variant="bodyBase">
                    {formValues.event_location}
                  </Typography>
                </Stack>
                <Stack>
                  <Typography fontWeight="bold" variant="subHeading">
                    Event Date
                  </Typography>
                  <Typography fontWeight="regular" variant="bodyBase">
                    {formValues.datepicker.c.day} /
                    {formValues.datepicker.c.month} /
                    {formValues.datepicker.c.year}
                  </Typography>
                </Stack>
                <Stack>
                  <Typography fontWeight="bold" variant="subHeading">
                    Event Time
                  </Typography>
                  <Typography fontWeight="regular" variant="bodyBase">
                    {formValues.from_time} to {formValues.to_time}
                  </Typography>
                </Stack>
              </Stack>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel3a-content"
              id="panel3a-header"
            >
              <Typography fontWeight="bold" variant="heading">
                Budget
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Stack spacing="1rem">
                <Stack>
                  <Typography fontWeight="bold" variant="subHeading">
                    Budget From
                  </Typography>
                  <Typography fontWeight="regular" variant="bodyBase">
                    {formValues.from}
                  </Typography>
                </Stack>
                <Stack>
                  <Typography fontWeight="bold" variant="subHeading">
                    Budget To
                  </Typography>
                  <Typography fontWeight="regular" variant="bodyBase">
                    {formValues.to}
                  </Typography>
                </Stack>
              </Stack>
            </AccordionDetails>
          </Accordion>
        </Stack>
      </Box>
    </Stack>
  );
};
