import { Stack } from '@mui/material';
import { isValidElement } from 'react';
import { NavArrowRight, NavArrowLeft } from 'iconoir-react';
import { useTheme } from '@mui/material/styles';

import { Typography } from '../Typography';
import { IconoirIcon } from '../IconoirIcon';

export function MenuItemContentRenderer({
  iconPosition = 'left',
  placement = 'right',
  icon,
  label,
  subMenuItems,
}) {
  const theme = useTheme();

  const renderIcon = isValidElement(icon) ? (
    icon
  ) : (
    <IconoirIcon
      icon={icon}
      width="2rem"
      height="2rem"
    />
  );

  return (
    <Stack
      flexDirection={placement === 'left' ? 'row-reverse' : 'row'}
      alignItems="center"
      justifyContent="space-between"
      width="100%"
    >
      <Stack
        gap=".5rem"
        alignItems="center"
        width="100%"
        justifyContent={iconPosition === 'right' ? 'space-between' : ''}
        flexDirection={placement === 'left' ? 'row-reverse' : 'row'}
      >
        {iconPosition === 'left' ? (
          <>
            {icon && renderIcon}
            <Typography variant="body">{label}</Typography>
          </>
        ) : (
          <>
            <Typography variant="body">{label}</Typography>
            {icon && renderIcon}
          </>
        )}
      </Stack>

      {subMenuItems && (
        <IconoirIcon
          color={theme.palette.primary.contrastText}
          width="2rem"
          height="2rem"
          icon={placement === 'right' ? NavArrowRight : NavArrowLeft}
        />
      )}
    </Stack>
  );
}
