import React, { useState } from "react";
import { Button } from "../Button";

import { Stepper, Step, StepLabel, Stack } from "@mui/material";

import { Typography } from "../Typography";

import { BottomSheetDialog } from "../BottomSheetDialog";
import { useStepper } from "../../hooks/useStepper";

import { PortfolioInput } from "./PortfolioInput";

import { ProProfile } from "./ProProfile";

import { isDisabled } from "./utils";

import { useForm } from "react-hook-form";

export const MusicianOnBoardingDialog = () => {
  const [isDialogOpen, setIsDialogOpen] = useState(true);

  const [formValues, setFormValues] = useState({});

  const proprofileResolver = async (values) => {
    const errors = {};
    const requiredFields = ["title", "bio", "languages"];

    requiredFields.forEach((field) => {
      if (!values[field]) {
        errors[field] = {
          type: "required",
          message: "This field is required",
        };
      }
    });

    // Validate skills array
    if (!values.skills || values.skills.length === 0) {
      errors.skills = {
        type: "required",
        message: "At least one skill is required",
      };
    } else {
      const skillErrors = values.skills
        .map((skill, index) => {
          const skillError = {};
          if (!skill.skill) {
            skillError.skill = {
              type: "required",
              message: "Skill is required",
            };
          }
          if (!skill.experience) {
            skillError.experience = {
              type: "required",
              message: "Experience is required",
            };
          }
          return Object.keys(skillError).length > 0 ? skillError : undefined;
        })
        .filter(Boolean);

      if (skillErrors.length > 0) {
        errors.skills = skillErrors;
      }
    }

    return {
      values: Object.keys(errors).length === 0 ? values : {},
      errors: Object.keys(errors).length > 0 ? errors : {},
    };
  };

  const {
    control: proprofileControl,
    watch: proprofileWatch,
    setValue: proprofileSetValue,
    handleSubmit: proprofileHandleSubmit,
    formState: { errors: proprofileErrors },
    reset: proprofileReset,
  } = useForm({
    mode: "all",
    resolver: proprofileResolver,
  });

  const socialsResolver = async (values) => {
    const errors = {};
    const requiredFields = ["socialLinks"];

    requiredFields.forEach((field) => {
      if (!values[field]) {
        errors[field] = {
          type: "required",
          message: "This field is required",
        };
      }
    });

    if (!values.socialLinks || values.socialLinks.length === 0) {
      errors.socialLinks = {
        type: "required",
        message: "At least one social link is required",
      };
    } else {
      const socialLinkErrors = values.socialLinks
        .map((socialLink, index) => {
          const socialLinkError = {};
          if (!socialLink.link) {
            socialLinkError.link = {
              type: "required",
              message: "Social link is required",
            };
          }
          return Object.keys(socialLinkError).length > 0
            ? socialLinkError
            : undefined;
        })
        .filter(Boolean);

      if (socialLinkErrors.length > 0) {
        errors.socialLinks = socialLinkErrors;
      }
    }

    return {
      values: Object.keys(errors).length === 0 ? values : {},
      errors: Object.keys(errors).length > 0 ? errors : {},
    };
  };

  const {
    control: socialsControl,
    watch: socialsWatch,
    setValue: socialsSetValue,
    formState: { errors: socialsErrors },
    handleSubmit: socialsHandleSubmit,
    reset: socialsReset,
  } = useForm({
    mode: "onBlur",
    resolver: socialsResolver,
  });

  const step1 = (
    <ProProfile
      control={proprofileControl}
      errors={proprofileErrors}
      watch={proprofileWatch}
      setValue={proprofileSetValue}
    />
  );
  const step2 = (
    <PortfolioInput
      control={socialsControl}
      errors={socialsErrors}
      watch={socialsWatch}
      setValue={socialsSetValue}
    />
  );

  const stepperState = {
    stepArray: [step1, step2],
    horizontalStepperList: ["Professional Profile", "Socials"],
  };

  const { currentStepIndex, step, isFirstStep, isLastStep, back, next, goTo } =
    useStepper(stepperState.stepArray);

  const handleStepSubmit = (stepIndex) => {
    const submitHandlers = {
      0: proprofileHandleSubmit,
      1: socialsHandleSubmit,
    };

    const submitHandler = submitHandlers[stepIndex];
    if (submitHandler) {
      submitHandler((formValues) => {
        setFormValues((prev) => ({ ...prev, ...formValues }));
      })();
    }
    if (stepIndex === 1) {
      console.log(formValues);
      socialsReset();
      proprofileReset();
    }
  };

  return (
    <Stack>
      <BottomSheetDialog
        title={
          <Typography variant="heading" fontWeight="bold">
            Provider Onboarding
          </Typography>
        }
        open={isDialogOpen}
        onClose={() => {
          goTo(0);
          setIsDialogOpen(false);
        }}
        actions={
          <Stack
            direction="row"
            justifyContent={isFirstStep ? "flex-end" : "space-between"}
            width="100%"
          >
            {!isFirstStep && (
              <Button autoFocus onClick={back}>
                Back
              </Button>
            )}
            <Button
              autoFocus
              disabled={isDisabled(
                currentStepIndex,
                proprofileWatch,
                proprofileErrors,
                socialsWatch,
                socialsErrors
              )}
              onClick={() => {
                handleStepSubmit(currentStepIndex);

                next();

                if (isLastStep) {
                  setIsDialogOpen(false);
                  goTo(0);
                }
              }}
            >
              {isLastStep ? "Submit" : "Next"}
            </Button>
          </Stack>
        }
      >
        <Stack spacing="3rem">
          <Stepper activeStep={currentStepIndex}>
            {stepperState.horizontalStepperList.map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
          {step}
        </Stack>
      </BottomSheetDialog>
      <Button onClick={() => setIsDialogOpen(true)}>Open Dialog</Button>
    </Stack>
  );
};
