import React, { useEffect } from "react";
import { ImageUploadField } from "../Forms/Fields/ImageUploadField";
import { Stack, Typography, Box, IconButton } from "@mui/material";
import { Button } from "../Button";
import { TextFieldV2 } from "../Forms/Fields/TextFieldV2";
import { useFieldArray } from "react-hook-form";
import RemoveIcon from "@mui/icons-material/Remove";
import { InputAdornment } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faInstagram,
  faLinkedin,
  faFacebook,
  faTwitter,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";

export const PortfolioInput = ({ control, errors, watch, setValue }) => {
  // const [socialLinks, setSocialLinks] = useState([<SocialLinkInput key={0} />]);

  const { fields, append, remove } = useFieldArray({
    control,
    name: "socialLinks",
  });

  const getSocialIcon = (link) => {
    const lowercaseLink = link.toLowerCase();
    if (lowercaseLink.includes("instagram")) return faInstagram;
    if (lowercaseLink.includes("linkedin")) return faLinkedin;
    if (lowercaseLink.includes("facebook")) return faFacebook;
    if (lowercaseLink.includes("twitter")) return faTwitter;
    if (lowercaseLink.includes("youtube")) return faYoutube;
    return; // Default icon
  };

  useEffect(() => {
    // Ensure there's always at least one skill field
    if (fields.length === 0) {
      append({ link: "" });
    }
  }, [fields, append]);

  const handleAddSocialLink = () => {
    append({ link: "" });
  };

  const handleRemoveSocialLink = (index) => {
    remove(index);
  };

  return (
    <Stack direction="row" justifyContent="space-between">
      <Stack gap="2rem" width="30%">
        <Typography variant="titlePage" fontWeight="bold">
          Showcase Your Talent
        </Typography>
        <Typography variant="subHeading">
          How would you like to showcase your work on your profile?
        </Typography>
      </Stack>
      <Box width="50%">
        <Stack spacing="2rem">
          {fields.map((field, index) => (
            <Stack
              key={field.id}
              spacing="0.5rem"
              direction="row"
              alignItems="flex-end"
            >
              <TextFieldV2
                control={control}
                name={`socialLinks.${index}.link`}
                label="Link up your socials"
                error={!!errors.socialLinks?.[index]?.link}
                helperText={errors.socialLinks?.[index]?.link?.message}
                placeholder="Enter Social Link"
                required
                {...(getSocialIcon(watch(`socialLinks.${index}.link`)) && {
                  slotProps: {
                    input: {
                      startAdornment: (
                        <InputAdornment position="start">
                          <FontAwesomeIcon
                            icon={getSocialIcon(
                              watch(`socialLinks.${index}.link`)
                            )}
                          />
                        </InputAdornment>
                      ),
                    },
                  },
                })}
              />
              {fields.length > 1 && (
                <IconButton
                  size="small"
                  onClick={() => handleRemoveSocialLink(index)}
                >
                  <RemoveIcon />
                </IconButton>
              )}
            </Stack>
          ))}
          <Stack
            direction="row"
            spacing="1rem"
            className="proprofile-skills-buttons"
          >
            <Button size="small" onClick={handleAddSocialLink}>
              Add Social Link
            </Button>
          </Stack>

          <Stack spacing={2}>
            <Typography variant="bodySmallStrong" fontWeight="bold">
              Upload your best work to impress potential clients and showcase
              your skills.
            </Typography>
            <Stack width="50%">
              <TextFieldV2
                control={control}
                name="portfolio"
                placeholder="Enter your portfolio link"
                slotProps={{
                  input: {
                    startAdornment: (
                      <InputAdornment position="start">
                        <FontAwesomeIcon icon={faInstagram} />
                      </InputAdornment>
                    ),
                  },
                }}
              />
            </Stack>
          </Stack>
          <Stack spacing={2}>
            <Typography variant="bodyStrong" fontWeight="bold">
              Please upload your tech rider for this event.
            </Typography>

            <ImageUploadField name="techRider" control={control} />
          </Stack>
        </Stack>
      </Box>
    </Stack>
  );
};
