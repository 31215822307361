import { Avatar, Stack } from '@mui/material';

import { AppBarLogoSkeleton } from './AppBarLogoSkeleton';
import { AppBarLogoStyled } from './AppBarLogo.styled';
import { MochshaLogo } from '../../../assets/logo/mochshaLogo';
import { Typography } from '../../Typography';

export function AppBarLogo({
  headerTitle,
  headerSubtitle,
  headerLogo = <MochshaLogo />,
  onLogoClick,
  isPanelOpen,
  loading,
}) {
  if (loading) {
    return <AppBarLogoSkeleton />;
  }

  return (
    <AppBarLogoStyled
      justifyContent={isPanelOpen ? 'initial' : 'center'}
      onClick={onLogoClick}
    >
      {typeof headerLogo === 'string' ? (
        <Avatar
          sx={{
            overflow: 'hidden',
            width: '4.4rem',
            height: '4.4rem',
          }}
          variant="rounded"
          src={headerLogo}
          alt={headerTitle}
        />
      ) : (
        headerLogo
      )}

      <Stack justifyContent="center">
        <Typography variant="subHeader1" fontWeight="bold">
          {headerTitle}
        </Typography>

        {headerSubtitle && (
          <Typography variant="subText" color="text.secondary">
            {headerSubtitle}
          </Typography>
        )}
      </Stack>
    </AppBarLogoStyled>
  );
}
