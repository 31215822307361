import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { updateMusician } from "../../store/musicianActions";
import { setProfilePicture } from "../../store/musicianActions";
import { AutocompleteField } from "../Forms/Fields/AutocompleteField/AutocompleteField";

import { TextFieldV2 } from "../Forms/Fields/TextFieldV2";
import cameraIcon from "../../assets/icons/camera.png";
import {
  Stack,
  Grid,
  TextField,
  Typography,
  Box,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
} from "@mui/material";
import { Button } from "../Button";

const PInfo = ({ control, errors = {}, watch, setValue }) => {
  const musicianData = useSelector((state) => state.musician.musicianData);
  const profilePicture = useSelector(
    (state) => state.musician.musicianData.profilePicture
  );
  const dispatch = useDispatch();
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const headers = new Headers();
    headers.append("X-CSCAPI-KEY", process.env.REACT_APP_CSCAPI_KEY);

    const requestOptions = {
      method: "GET",
      headers: headers,
      redirect: "follow",
    };

    fetch(
      "https://api.countrystatecity.in/v1/countries/IN/states",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        console.log(JSON.stringify(result));
        const stateNames = result.map((state) => state.name);
        setStates(stateNames);
      })
      .catch((error) => {
        console.error("Error fetching states:", error);
      });
  }, []);

  const fetchCities = (stateId) => {
    console.log("Fetch cities" + stateId);
    const headers = new Headers();
    headers.append("X-CSCAPI-KEY", process.env.REACT_APP_CSCAPI_KEY);

    const requestOptions = {
      method: "GET",
      headers: headers,
      redirect: "follow",
    };

    fetch(
      `https://api.countrystatecity.in/v1/countries/IN/states/${stateId}/cities`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        const cityNames = result.map((city) => city.name);
        setCities(cityNames);
      })
      .catch((error) => {
        console.error("Error fetching cities:", error);
      });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    console.log("Name:", name, "Value:", value);
    dispatch(updateMusician(name, value));
  };

  const handleProfileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        dispatch(setProfilePicture(reader.result));
      };
      reader.readAsDataURL(file);
    }
  };

  const handleLocationChange = (e) => {
    console.log("Location change:", e.target.id, e.target.value);
    const { id, value } = e.target;
    if (id === "state") {
      console.log("Fetching cities for state:", value);
      fetchCities(value);
    }
  };

  const handleCombinedChange = (e) => {
    const { name, value } = e.target;
    handleLocationChange(e);
    handleChange({ target: { name: `location.${name}`, value } });
  };

  const triggerFileInput = () => {
    document.getElementById("profilePicture").click();
  };

  return (
    <Stack>
      <Stack spacing="2rem">
        <Typography variant="titlePage" fontWeight="bold">
          Tell Us About You
        </Typography>
        <Typography variant="subHeading">
          Let's get to know you better!
        </Typography>
        <Stack spacing="2rem">
          <Grid container spacing="2rem" alignItems="center">
            <Grid item xs={12} md={2}>
              <Typography variant="bodyStrong" fontWeight="bold">
                Full Name
              </Typography>
            </Grid>
            <Grid item xs={12} md={5}>
              <TextFieldV2
                control={control}
                placeholder="First Name"
                name="firstName"
                label="First Name"
                error={!!errors?.firstName}
                helperText={errors?.firstName?.message}
                required
              />
            </Grid>
            <Grid item xs={12} md={5}>
              <TextFieldV2
                control={control}
                placeholder="Last Name"
                label="Last Name"
                name="lastName"
                error={!!errors.lasttName}
                helperText={errors.lastName?.message}
                required
              />
            </Grid>
          </Grid>
          <Grid container spacing="2rem" alignItems="center">
            <Grid item xs={12} md={2}>
              <Typography variant="bodyStrong" fontWeight="bold">
                Display Name
              </Typography>
            </Grid>
            <Grid item xs={12} md={10}>
              <TextFieldV2
                control={control}
                placeholder="Display Name"
                name="displayName"
                error={!!errors.displaytName}
                helperText={errors.displayName?.message}
                // required
              />
            </Grid>
          </Grid>

          <Grid container spacing="2rem" alignItems="center">
            <Grid item xs={12} md={2}>
              <Typography variant="bodyStrong" fontWeight="bold">
                Profile Picture
              </Typography>
            </Grid>
            <Grid item xs={12} md={5}>
              <Box
                sx={{
                  display: "flex",
                  height: "15rem",
                  width: "15rem",
                  borderRadius: "50%",
                  border: "1px solid black",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <input
                  type="file"
                  accept="image/*"
                  onChange={handleProfileChange}
                  style={{ display: "none" }}
                  id="profile-picture-upload"
                />
                <label htmlFor="profile-picture-upload">
                  <img
                    src={profilePicture || cameraIcon}
                    alt="Camera Icon"
                    style={{ width: "2rem", height: "2rem", cursor: "pointer" }}
                  />
                </label>
              </Box>
            </Grid>
            <Grid item xs={12} md={5}>
              <Button className="upload-button" onClick={triggerFileInput}>
                Upload a picture
              </Button>
              <input
                type="file"
                id="profilePicture"
                name="profilePicture"
                style={{ display: "none" }}
                onChange={handleProfileChange}
              />
            </Grid>
          </Grid>

          <Grid container spacing="2rem" alignItems="center">
            <Grid item xs={12} md={2}>
              <Typography variant="bodyStrong" fontWeight="bold">
                Location
              </Typography>
            </Grid>
            <Grid item xs={12} md={5}>
              <AutocompleteField
                control={control}
                id="state"
                name="state"
                label="State"
                error={!!errors.state}
                helperText={errors.state?.message}
                placeholder="Select State"
                options={states}
                required
              />
            </Grid>
            <Grid item xs={12} md={5}>
              <AutocompleteField
                control={control}
                name="city"
                label="City"
                error={!!errors.city}
                helperText={errors.city?.message}
                placeholder="Select city"
                options={cities}
                required
              />
            </Grid>
          </Grid>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default PInfo;
