import React from "react";
import { useStepper } from "../../hooks/useStepper";
import { isDisabled } from "./utils";
import { useForm } from "react-hook-form";
import {
  Stepper,
  Step,
  StepLabel,
  Box,
  DialogTitle,
  IconButton,
  DialogActions,
  DialogContent,
} from "@mui/material";
import { Button } from "../Button";
import { Container, ButtonContainer } from "./StepperPage.styled.jsx";

const StepperPage = ({ steps, title, actions, onClose, ...rest }) => {
  const { currentStepIndex, goToNextStep, goToPreviousStep } = useStepper(
    steps.length
  );
  const {
    control,
    watch,
    formState: { errors },
  } = useForm();

  const currentStep = steps[currentStepIndex];
  const currentStepWatch = watch(currentStep.watchName);
  const currentStepErrors = errors[currentStep.watchName];

  return (
    <Container {...rest}>
      <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
        {title}
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={onClose}
        sx={(theme) => ({
          position: "absolute",
          right: 8,
          top: 8,
          color: theme.palette.grey[500],
        })}
      ></IconButton>
      <DialogContent sx={{ paddingX: "4rem", paddingY: "2rem" }}>
        <Stepper activeStep={currentStepIndex}>
          {steps.map((step, index) => (
            <Step key={index}>
              <StepLabel>{step.label}</StepLabel>
            </Step>
          ))}
        </Stepper>
        <Box mt={2}>
          {React.createElement(currentStep.component, { control })}
          <ButtonContainer>
            <Button
              disabled={currentStepIndex === 0}
              onClick={goToPreviousStep}
            >
              Back
            </Button>
            <Button
              disabled={isDisabled(
                currentStepIndex,
                currentStepWatch,
                currentStepErrors
              )}
              onClick={goToNextStep}
            >
              {currentStepIndex === steps.length - 1 ? "Finish" : "Next"}
            </Button>
          </ButtonContainer>
        </Box>
      </DialogContent>
      {actions && (
        <DialogActions sx={{ py: "0.8rem", px: "2.4rem" }}>
          {actions}
        </DialogActions>
      )}
    </Container>
  );
};

export default StepperPage;
