import React from "react";

import { Stack, Box } from "@mui/material";

import { AutocompleteField } from "../Forms/Fields/AutocompleteField/AutocompleteField";

import { Typography } from "../Typography";

import { TextFieldV2 } from "../Forms/Fields/TextFieldV2";

import {
  jobCategoryOptions,
  vocalistCategoryOptions,
  instrumentalistCategoryOptions,
  languageOptions,
  genreOptions,
} from "./utils";

export const About = ({ control, errors, watch, setValue }) => {
  const onCategoryChange = (_, data) => {
    // setValue("subCategory", null);
  };

  const onSubCategoryChange = (_, data) => {};

  return (
    <Stack direction="row" justifyContent="space-between">
      <Stack gap="2rem" width="30%">
        <Typography variant="titlePage" fontWeight="bold">
          Bring Your Event to Life!
        </Typography>
        <Typography variant="subHeading">
          Find the talent you need to bring your vision to life.
        </Typography>
      </Stack>

      <Box width="50%">
        <Stack spacing="4rem">
          <TextFieldV2
            control={control}
            name="jobTitle"
            label="Lets start with a clear and catchy job title"
            error={!!errors.jobTitle}
            helperText={errors.jobTitle?.message}
            placeholder="Eg. Live Band/ Sound"
            required
          />

          <TextFieldV2
            control={control}
            name="description"
            label="Describe the job and its requirements"
            error={!!errors.description}
            helperText={errors.description?.message}
            placeholder="Eg. Looking for a live Band/Sound Engineer vendor for our event"
            required
          />

          <Stack direction="row" spacing="3rem">
            <AutocompleteField
              control={control}
              name="language"
              label="Language"
              error={!!errors.language}
              helperText={errors.language?.message}
              placeholder="Select Language"
              options={languageOptions}
              required
            />
            <AutocompleteField
              control={control}
              name="genre"
              label="Genre"
              error={!!errors.genre}
              helperText={errors.genre?.message}
              placeholder="Select Genre"
              options={genreOptions}
              required
            />
          </Stack>

          <Stack direction="row" spacing="3rem">
            <AutocompleteField
              control={control}
              name="category"
              label="Categorize your job"
              error={!!errors.category}
              helperText={errors.category?.message}
              placeholder="Select Category"
              onInputChange={onCategoryChange}
              options={jobCategoryOptions}
              required
            />
            <AutocompleteField
              control={control}
              disabled={!watch("category")}
              name="subCategory"
              label="Choose a sub-category"
              onInputChange={onSubCategoryChange}
              error={!!errors.subCategory}
              helperText={errors.subCategory?.message}
              placeholder="Select Sub-Category"
              options={
                watch().category === "vocalist"
                  ? vocalistCategoryOptions
                  : instrumentalistCategoryOptions
              }
              required
            />
          </Stack>
        </Stack>
      </Box>
    </Stack>
  );
};
