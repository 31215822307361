import React, { useState, useEffect } from "react";
// import "./ProProfile.css";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { updateMusician } from "../../store/musicianActions";
import { useFieldArray } from "react-hook-form";
import {
  Stack,
  Typography,
  TextField,
  Select,
  MenuItem,
  Chip,
  Box,
  useTheme,
  IconButton,
} from "@mui/material";

import RemoveIcon from "@mui/icons-material/Remove";
import { Button } from "../Button";

import { AutocompleteField } from "../Forms/Fields/AutocompleteField/AutocompleteField";

import { TextFieldV2 } from "../Forms/Fields/TextFieldV2";

import { languageOptions, skillOptions, experienceOptions } from "./utils";

export const ProProfile = ({ control, errors, watch, setValue }) => {
  const { fields, append, remove } = useFieldArray({
    control,
    name: "skills",
  });

  useEffect(() => {
    // Ensure there's always at least one skill field
    if (fields.length === 0) {
      append({ skill: "", experience: "" });
    }
    console.log("Current fields:", fields);
  }, [fields, append]);

  const handleAddSkill = () => {
    append({ skill: "", experience: "" });
  };

  const handleRemoveSkill = (index) => {
    remove(index);
  };

  return (
    <Stack direction="row" justifyContent="space-between">
      <Stack gap="2rem" width="30%">
        <Typography variant="titlePage" fontWeight="bold">
          Build Your Professional Profile
        </Typography>
        <Typography variant="subHeading">
          Let others know how awesome you are!
        </Typography>
      </Stack>

      <Box width="50%">
        <Stack spacing="2rem">
          <TextFieldV2
            control={control}
            name="title"
            label="How would you describe yourself best?"
            error={!!errors.title}
            helperText={errors.title?.message}
            placeholder="Eg. Musician/Sound Engineer/Light Engineer"
            required
          />
          <TextFieldV2
            control={control}
            name="bio"
            label="Briefly describe your professional background and experience"
            error={!!errors.bio}
            helperText={errors.bio?.message}
            placeholder="Eg. "
            required
          />
          <AutocompleteField
            control={control}
            name="languages"
            freeSolo
            label="Languages you perform in"
            multiple
            error={!!errors.languages}
            placeholder="Select Languages"
            helperText={errors.languages?.message}
            options={languageOptions}
            required
          />
          <Typography variant="bodyStrong" fontWeight="bold">
            What are your core skills related to the music industry?
          </Typography>
          {fields.map((field, index) => (
            <Stack
              key={field.id}
              spacing="0.5rem"
              direction="row"
              alignItems="flex-end"
            >
              <AutocompleteField
                control={control}
                name={`skills.${index}.skill`}
                label="Skill"
                error={!!errors.skills?.[index]?.skill}
                helperText={errors.skills?.[index]?.skill?.message}
                placeholder="Select Skill"
                options={skillOptions}
                required
              />
              <AutocompleteField
                control={control}
                name={`skills.${index}.experience`}
                label="Experience"
                error={!!errors.skills?.[index]?.experience}
                helperText={errors.skills?.[index]?.experience?.message}
                placeholder="Select Experience"
                options={experienceOptions}
                required
              />
              {fields.length > 1 && (
                <IconButton
                  size="small"
                  onClick={() => handleRemoveSkill(index)}
                >
                  <RemoveIcon />
                </IconButton>
              )}
            </Stack>
          ))}
          <Stack
            direction="row"
            spacing="1rem"
            className="proprofile-skills-buttons"
          >
            <Button size="small" onClick={handleAddSkill}>
              Add Skill
            </Button>
          </Stack>
        </Stack>
      </Box>
    </Stack>
  );
};
