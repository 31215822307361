import { styled, Box } from "@mui/material";

export const Container = styled(Box)`
  padding: 2rem;
`;

export const ButtonContainer = styled(Box)`
  display: flex;
  justify-content: space-between;
  margin-top: 2rem;
`;
