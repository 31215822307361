import React, { useState } from "react";
// import "./Onboarding.css";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faGoogle,
  faFacebookF,
  faTwitter,
} from "@fortawesome/free-brands-svg-icons";
import logo from "../../assets/logo/Completelogo.png";
import axios from "axios";
import { Button } from "../../components/Button";
import {
  TextField,
  Stack,
  Typography,
  CircularProgress,
  IconButton,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";

const Onboarding = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    email: "",
    password: "",
    retypePassword: "",
  });
  const [error, setError] = useState("");

  // Validation functions
  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+.[^\s@]+$/;
    return re.test(String(email).toLowerCase());
  };

  const validatePassword = (password) => {
    const re =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
    return re.test(password);
  };

  const handleGoogle = async () => {
    try {
      window.location.href = "http://localhost:5000/auth/google";
    } catch (error) {
      console.error("Error signing up with Google:", error);
      setError("An error occurred during sign up");
    }
  };

  // Input change handler
  const handleChange = (e) => {
    const { id, value } = e.target;
    console.log(id, value);
    setFormData((prevData) => ({
      ...prevData,
      [id]: value,
    }));
  };

  // Sign up handler
  const handleSignUp = async (e) => {
    e.preventDefault();

    if (formData.password !== formData.retypePassword) {
      setError("Passwords do not match");
      return;
    }
    if (!formData.email || !formData.password) {
      setError("Fill all the required fields");
      return;
    }

    if (!validateEmail(formData.email)) {
      setError("Invalid email address");
      return;
    }

    if (!validatePassword(formData.password)) {
      setError(
        "Password must be at least 8 characters long and include at least one uppercase letter, one lowercase letter, one number, and one special character"
      );
      return;
    }

    try {
      setLoading(true);
      console.log("Form Data" + formData.email);
      const response = await axios.post(
        "http://localhost:5000/api/v1/users/register",
        {
          email: formData.email,
          password: formData.password,
        }
      );
      if (response && response.data) {
        console.log("Sign up successful:", response.data);
        navigate("/onboarding/otp", { state: { email: formData.email } });
      } else {
        console.error("Unexpected response structure:", response);
        setError("An error occurred during sign up");
      }
    } catch (error) {
      if (error.response && error.response.status === 409) {
        setError("Email already exists");
      } else {
        console.error("Error signing up:", error);
        setError("An error occurred during sign up");
      }
    } finally {
      setLoading(false);
    }
  };

  const theme = useTheme();

  return (
    <Stack
      direction="row"
      spacing="4rem"
      justifyContent="space-around"
      alignItems="center"
      height="90vh"
      sx={{
        backgroundColor: theme.mochshaColorPalette.mistyMint[900],
      }}
    >
      <Stack spacing="2rem">
        <Typography variant="titlePage" fontWeight="bold">
          Join the Groove at Mochsha!
        </Typography>
        <Typography variant="subHeading">
          MUSIC | ART | CONNECTIONS | TRANSPARENCY
        </Typography>
        <Stack spacing="2rem" alignItems="flex-end">
          <img src={logo} alt="Website Logo" style={{ width: "40rem" }} />
        </Stack>
      </Stack>
      <Stack spacing="2rem" width="40%">
        <Typography variant="bodyStrong" fontWeight="bold">
          Email
        </Typography>
        <TextField
          id="email"
          type="email"
          fullWidth
          value={formData.email}
          onChange={handleChange}
        />
        <Typography variant="bodyStrong" fontWeight="bold">
          Password
        </Typography>
        <TextField
          id="password"
          type="password"
          fullWidth
          value={formData.password}
          onChange={handleChange}
        />
        <Typography variant="bodyStrong" fontWeight="bold">
          Retype Password
        </Typography>
        <TextField
          id="retypePassword"
          type="password"
          fullWidth
          value={formData.retypePassword}
          onChange={handleChange}
        />
        {error && <Typography color="error">{error}</Typography>}
        <Typography align="center">or</Typography>
        <Stack direction="row" spacing={2} justifyContent="center">
          <IconButton
            sx={{
              width: "5rem",
              height: "5rem",
              borderRadius: "50%",
              border: "1px solid black",
            }}
          >
            <FontAwesomeIcon icon={faFacebookF} />
          </IconButton>
          <IconButton
            onClick={handleGoogle}
            sx={{
              width: "5rem",
              height: "5rem",
              borderRadius: "50%",
              border: "1px solid black",
            }}
          >
            <FontAwesomeIcon icon={faGoogle} />
          </IconButton>
          <IconButton
            sx={{
              width: "5rem",
              height: "5rem",
              borderRadius: "50%",
              border: "1px solid black",
            }}
          >
            <FontAwesomeIcon icon={faTwitter} />
          </IconButton>
        </Stack>
        <Stack spacing={2} alignItems="center">
          <Button onClick={handleSignUp} disabled={loading} fullWidth>
            {loading ? <CircularProgress size={24} /> : "Sign Up"}
          </Button>
          <Typography>
            Already have an account? <a href="/onboarding/login">Log in</a>
          </Typography>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default Onboarding;
