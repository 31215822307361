import React from "react";
import Login from "../../components/Onboarding/login";

const OnboardingPage = () => {
  return (
    <div className="onboarding-page">
      <Login />
    </div>
  );
};

export default OnboardingPage;
