import { styled } from '@mui/material/styles';
import { Toolbar } from '@mui/material';

export const SubscriptionToolbarStyled = styled(Toolbar)(({ theme }) => {
  const { galacticGrape, cosmicTangerine } = theme.mochshaColorPalette;

  return {
    background: `linear-gradient(to right, ${cosmicTangerine[900]} 70%, ${galacticGrape[200]})`,
    color: galacticGrape[200],
    paddingInline: '2rem',
    paddingBlock: '0.8rem',
  };
});
