import { useRef, useState } from "react";
import { Box } from "@mui/material";

import { ImagePreview } from "./ImagePreview";
import { ImagePlaceholder } from "./ImagePlaceholder";

export function ImageUploadInput({
  file,
  onChange,
  accept = "image/png, image/jpeg, image/jpg, application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document"
}) {
  const fileInputRef = useRef(null);
  const [error, setError] = useState(false);

  const onChangeHandler = (e) => {
    const newFile = e.target.files?.[0];
    const acceptedTypes = accept.split(", ");

    if (!newFile) {
      return;
    }

    const fileType = newFile.type;
    const fileExtension = newFile.name.split(".").pop().toLowerCase();

    const isAccepted = acceptedTypes.some((type) => {
      if (type.startsWith("image/")) {
        return fileType === type;
      } else if (type === "application/pdf") {
        return fileType === "application/pdf" || fileExtension === "pdf";
      } else if (type === "application/msword") {
        return fileType === "application/msword" || fileExtension === "doc";
      } else if (
        type ===
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
      ) {
        return (
          fileType ===
            "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ||
          fileExtension === "docx"
        );
      }
      return false;
    });

    if (!isAccepted) {
      setError(true);
      return;
    }

    setError(false);
    onChange(newFile);
  };

  const onImageRemoveHandler = () => {
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }

    onChange("");
  };

  const onImageReuploadHandler = () => {
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
      fileInputRef.current.click();
    }
  };

  const onImageUploadHandler = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  return (
    <Box>
      <Box component="label">
        <input
          accept={accept}
          hidden
          type="file"
          onChange={onChangeHandler}
          ref={fileInputRef}
        />
      </Box>

      {file ? (
        <ImagePreview
          onImageRemoveHandler={onImageRemoveHandler}
          onImageReuploadHandler={onImageReuploadHandler}
          file={file}
        />
      ) : (
        <ImagePlaceholder
          error={error}
          onImageUploadHandler={onImageUploadHandler}
        />
      )}
    </Box>
  );
}
